.calendarCls {

    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid !important;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0 !important;
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: flex;
    flex: auto
}

.react-date-picker__wrapper {
    border: 0 !important;
}

.react-date-picker__inputGroup__input {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    color: #768192;
}


.react-date-picker__button {
    border: 0;
    background: transparent;
    padding: inherit;
    padding-inline: 4px;

}

.react-date-picker__button svg {
    stroke: #768192;
}